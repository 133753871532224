import React from "react"
import { isEmpty, get } from "lodash"
import { getCurrentTab, getTabLists, collectMenuTabs } from "../../helper"
import { withSubtheme } from "../../../../StarberryComponentsMui"
import Flexbox from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
import GlobalLayout from "../../GlobalLayout"
import Tabs from "../../tabs"
import MenuListTabs from "../../iconMenuList/menuListIconsTabs"
import SingleProperty from "../../property/singleProperty"
import PendingItems from "../../pendingItems"
import PageTitle from "../../pageTitle"
// import Activities from '../../activities';
// import FeedbackProgress from '../../feedbackProgress';
import Container from "@mui/material/Container"
import FloatingContainer from "../../floatingContainer"
import BookViewingBtn from "../../property/components/bookViewing"
import MakeOfferBtn from "../../property/components/makeOffer"
import ContactBtn from "../../property/components/contactBtn"
import _properties from "../../../../sample/properties.yaml"
import {
    // MyaccountStoreProvider,
    // PropertyActionStoreProvider,
    // useMyaccountState,
    // usePropertyActionState,
    useAuthState,
} from "../../../../services"
// import Actions from '../../actions';
import PendingActions from "../../pendingActions/propertyDetails"
import RecentActivites from "../../recentActivities/propertyDetails"
import NoActions from "../../progress/NoActions"
// import CurrentTenancy from '../../currentTenancy';
// import { MYACCOUNT_INBOX } from "../../../../constants/urls";
import DocumentContent from "../../pages/my-property/common/document"
import AppointmentsContent from "../../pages/my-property/common/appointment"
import FeedbackContent from "../../pages/my-property/common/feedback"
import OfferContent from "../../pages/my-property/common/offer"
import ProofOfFunds from "../../user/proofofFunds"
import ContactModuleComp from "../../pages/my-property/common/contactModule"
import MyPropertySkeleton from "./myPropertySkeleton"
import defaults from "./defaults"
import {
    useGetMyPropertyQuery,
    propertyApi,
} from "./../../../../redux/services/property"
import store from "./../../../../redux/store"
import { isAuthenticated } from "../../../../services/store/utils"

// --------------------------------------------------------------------------
// SINGLE COMPONENTS USED IN MOBILE AND DESKTOP LAYOUT

const BookViewingBtnWrapComp = props => {
    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                "PendingActionCount",
                "PropertyPendingAction",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <BookViewingBtn {...props} reloadDataFun={reloadDataFun} />
}

const FloatingContainerComp = props => {
    const { showMakeAnOfferBtn, showBookaViewingBtn } = props.actionParams
    let showContactBtn = false
    if (isEmpty(props.negInfo)) {
        showContactBtn = true
    }
    return (
        <FloatingContainer
            altClassName={`${!!props?.makeAnOffer ? "has-offer-btn" : ""}`}
        >
            {!!props?.makeAnOffer && !!showMakeAnOfferBtn && (
                <MakeOfferBtn {...props} />
            )}
            {!!showBookaViewingBtn && <BookViewingBtnWrapComp {...props} />}
            {showContactBtn && <ContactBtn {...props} />}
        </FloatingContainer>
    )
}

const SinglePropertyComp = props => {
    let offerText = ""
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`
    }

    let property = _properties[0] // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText,
        }
    }

    return (
        <>
            <SingleProperty
                properties={[property]}
                altClassName={`persona-details`}
            />
        </>
    )
}

// const CurrentTenancyComp = (props) => {
//
//     const { property_detail, offers } = props
//
//     if (isEmpty(property_detail) && !offers.length)
//         return <></>
//
//     let currentData = [
//         {
//             "id": "1",
//             "title": "Address:",
//             "value": property_detail?.display_address
//         }
//     ]
//
//     if (offers.length) {
//         offers.map((offer, key) => {
//             currentData.push({
//                 "id": `offers-${key}`,
//                 "title": "Offer:",
//                 "value": `${formatPrice(offer.amount)} (${offer.status})`
//             })
//         })
//     }
//     return (
//         <>
//             <CurrentTenancy
//                 title="Buying"
//                 data={currentData}
//             />
//         </>
//     )
// }

// END SINGLE COMPONENTS USED IN MOBILE AND DESKTOP LAYOUT
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// TAB COMPONENT CONTENT GRID
const OverviewContent = props => {
    const progressionData = props.property?.sales_progression || []
    const isMobile = props.mobile
    if (isMobile) {
        return (
            <Flexbox container spacing={0}>
                <Flexbox item xs={12}>
                    <TabsMobileComp {...props} />
                </Flexbox>
            </Flexbox>
        )
    } else {
        return (
            <Flexbox container spacing={1}>
                <Flexbox item xs={12} lg={8}>
                    <Flexbox container spacing={1}>
                        {/*<Flexbox item xs={12}>
                            <CurrentTenancyComp {...props.property} />
                        </Flexbox>
                        */}
                        <Flexbox item xs={12}>
                            <ActivityTabsComp
                                theme={props.theme}
                                property={props.property}
                            />
                        </Flexbox>
                        <Flexbox item xs={12}>
                            <PendingItems
                                title="Sales Progress"
                                data={progressionData}
                            />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Flexbox item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                    <FloatingContainerComp
                        actionParams={props.buyer}
                        theme={props.theme}
                        property={props.property?.property_detail}
                        makeAnOffer={props.property?.appointments.length}
                        offerParams={props?.offer}
                        negInfo={props.property?.negotiator_info}
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        property={props.property?.property_detail}
                        data={props.property?.negotiator_info}
                        moduleTitle="Contact your agent"
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        property={props.property?.property_detail}
                        data={props.property?.manager_negotiator_info}
                        moduleTitle="Property manager"
                    />
                </Flexbox>
            </Flexbox>
        )
    }
}

// END TAB COMPONENT CONTENT GRID
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
const mainTabData = props => {
    return {
        overview: {
            id: "overview",
            iconName: "overviewTabIcon",
            name: "Overview",
            subtitle: "Lorem ipsum dolor sit amet",
            content: <OverviewContent {...props} />,
        },
        appointments: {
            id: "appointments",
            iconName: "appointmentsIcon",
            name: "Appointments",
            subtitle: "Lorem ipsum dolor sit amet",
            content: <AppointmentsContent {...props} />,
        },
        feedback: {
            id: "feedback",
            iconName: "emailIcon",
            name: "Feedback",
            subtitle: "Lorem ipsum dolor sit amet",
            content: <FeedbackContent {...props} />,
        },
        offers: {
            id: "offers",
            iconName: "offerIcon",
            name: "Offers",
            content: <OfferContent {...props} role="buyer" />,
        },
        bids: {
            id: "bids",
            iconName: "offerIcon",
            name: "Bids",
            content: <OfferContent {...props} role="buyer" />,
        },
        documents: {
            id: "documents",
            iconName: "documentsIcon",
            name: "Documents",
            content: <DocumentContent {...props} />,
        },
        proofoffunds: {
            id: "proofoffunds",
            iconName: "documentsIcon",
            name: "Proof of Funds",
            content: <ProofOfFunds {...props} />,
        },
    }
}
const MainTabsComp = props => {
    const { services: authServices } = useAuthState()
    const property = props?.property
    const pageTitle = property?.property_detail?.display_address

    // tabItems Can be Array or Objects
    const _tabItems = [
        "overview",
        "appointments",
        "feedback",
        "offers" /*, 'documents'*/,
    ]
    const _defaultItem = "overview"
    const tabItems = props?.tab?.items || _tabItems
    const defaultItem = props?.tab?.default || _defaultItem
    const currentTab = getCurrentTab(defaultItem, tabItems)
    const availableTabs = mainTabData(props) || {}
    const data = getTabLists(availableTabs, tabItems)

    const [value, setValue] = React.useState(currentTab)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    React.useEffect(() => {
        const pageRightDrawer = (
            <RightDrawerContent
                props={{
                    property: property,
                    manageValue: value,
                    manageHandleChange: handleChange,
                    tabItems: tabItems,
                    addUrl: true,
                    data: data,
                }}
            />
        )
        authServices.updateState({
            pageTitle: pageTitle,
            pageRightDrawer: pageRightDrawer,
            pageLeftIcon: true,
        })
    }, [pageTitle, value]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tabs
            data={data}
            manageValue={value}
            tabItems={tabItems}
            addUrl={true}
            altTabClassName={`pd-main`}
        />
    )
}

const ActivityTabsComp = props => {
    const dataActivity = [
        {
            id: "tab1",
            name: "Pending Actions",
            content: <PendingActions property={props?.property} />,
        },
        {
            id: "tab2",
            name: "Recent Activity",
            content: <RecentActivites property={props?.property} />,
        },
    ]
    return <Tabs data={dataActivity} altTabClassName={`pd-second`} />
}

const mobileTabData = props => {
    let defaultTabs = ["Overview", "Activity"]
    let tabs = []
    if (
        get(props, "overviewTabs.items", false) &&
        props?.overviewTabs?.items?.length > 2
    ) {
        defaultTabs = props.overviewTabs.items
    }

    for (let index = 0; index < defaultTabs.length; index++) {
        const tab = defaultTabs[index]

        switch (tab) {
            case "Overview":
                tabs.push({
                    id: `tab${index + 1}`,
                    name: tab,
                    content: (
                        <>
                            <ContactModuleComp
                                simpleContact={true}
                                property={props.property?.property_detail}
                                data={{
                                    ...props.property?.negotiator_info,
                                    helpTextLink: "Get in touch",
                                    helpText: "Need help? ",
                                }}
                            />
                            {/*<CurrentTenancyComp {...props.property} /> */}
                            <PendingActions
                                property={props?.property}
                                title="Pending Actions"
                                componentLayout={true}
                                // moreLabel="View all"
                                // moreLinkUrl={MYACCOUNT_INBOX}
                                // noActionTitle="There are no pending actions"
                                // noActionMessage="At the moment all the actions are closed..."
                            />

                            <FloatingContainerComp
                                actionParams={props.buyer}
                                property={props.property?.property_detail}
                                makeAnOffer={
                                    props.property?.appointments.length
                                }
                                offerParams={props?.offer}
                            />
                        </>
                    ),
                })
                break
            case "Bids":
                tabs.push({
                    id: `tab${index + 1}`,
                    name: "My Bids",
                    content: <OfferContent {...props} role="buyer" />,
                })
                break
            case "Activity":
                tabs.push({
                    id: `tab${index + 1}`,
                    name: tab,
                    content: <RecentActivites property={props?.property} />,
                })
                break
            default:
                break
        }
    }

    return tabs
}

const TabsMobileComp = props => {
    return (
        <Tabs
            data={mobileTabData(props)}
            fullWidthTabs={true}
            altTabClassName={`pd-second`}
        />
    )
}

// END MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
// --------------------------------------------------------------------------

const MyAccountPropertySellerHeaderProperty = withSubtheme(props => {
    const { className, properties, property } = props

    return (
        <div className={className}>
            <SinglePropertyComp
                properties={properties}
                property={property?.property_detail}
            />
        </div>
    )
}, "myPropertyPageSingleHeaderProperty")

const MenuListTabsComp = props => {
    const origData = props?.data || mainTabData(props)
    // Create a new array with only the id and name properties, we don't need the rest for the menus
    const newData = collectMenuTabs(origData)

    return (
        <MenuListTabs
            {...props}
            title={props.title && props.title}
            data={newData}
        />
    )
}

const RightDrawerContent = ({ props }) => {
    return <MenuListTabsComp {...props} />
}

const MyAccountPropertyBuyerInit = React.memo(({ props }) => {
    // const { state } = useMyaccountState()
    const { theme } = props
    const properties =
        _properties ?? theme.getProp("subthemes.result.sampleData")
    const mobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true })

    const property = props?.property //state?.property || {}
    const property_detail = property?.property_detail || {}
    const pageTitle = property_detail?.display_address

    if (props.isLoading && isEmpty(property)) {
        return <MyPropertySkeleton tabsCount={7} />
    }

    if (!props.isLoading && isEmpty(property_detail)) {
        return (
            <NoActions
                title="Property not found"
                message="At the moment this property can't be found"
                icon="propertyNotFound"
            />
        )
    }

    return (
        <Flexbox container spacing={1}>
            <Flexbox item xs={12}>
                <PageTitle theme={theme}>{pageTitle}</PageTitle>
            </Flexbox>
            {mobile && (
                <Flexbox item xs={12}>
                    <MyAccountPropertySellerHeaderProperty
                        theme={theme}
                        properties={properties}
                        property={property}
                    />
                </Flexbox>
            )}
            <Flexbox item xs={12}>
                <MainTabsComp
                    {...props}
                    mobile={mobile}
                    theme={theme}
                    properties={properties}
                    // manageValue={value}
                    // manageHandleChange={handleChange}
                    property={property}
                />
            </Flexbox>
        </Flexbox>
    )
})

const WrapComp = React.memo(props => {
    const authUser = isAuthenticated()
    const { theme, propertyid } = props

    const {
        data: property,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetMyPropertyQuery({ id: propertyid }, { skip: !authUser })

    if (isLoading) {
        return <MyPropertySkeleton tabsCount={5} />
    }

    return (
        <Container maxWidth="xl">
            <MyAccountPropertyBuyerInit
                props={{
                    ...props,
                    theme: theme,
                    property: property,
                    error: error,
                    isError: isError,
                    isLoading: isLoading,
                }}
            />
        </Container>
    )
})

const MyAccountPropertyBuyer = withSubtheme(
    props => {
        return (
            <GlobalLayout>
                {/* <MyaccountStoreProvider> */}
                {/* <PropertyActionStoreProvider> */}
                <WrapComp {...props} />
                {/* </PropertyActionStoreProvider> */}
                {/* </MyaccountStoreProvider> */}
            </GlobalLayout>
        )
    },
    "myPropertyPageBuyer",
    defaults
)

export default React.memo(MyAccountPropertyBuyer)
